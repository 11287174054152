import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import actionsBuild from "./actions"
import { commonStateBuild, commonGetters } from "@/config/store/matching/shared"

const BASE_URI = "/prices_car_classes"

const store = new StoreItemsModule({ baseURI: BASE_URI })

store.mergeState(commonStateBuild())
store.mergeGetters(commonGetters)

const { FETCH_ITEMS } = actionsBuild({ baseURI: BASE_URI })
store.mergeActions({ FETCH_ITEMS }, withLoading)

export default store
