import { api } from "@/config"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit }, customParams = {}) => {
    const { headers, ...rest } = customParams

    const params = { ...rest, pagination: { _disabled: true } }

    const response = await api.get(baseURI, { params, headers })
    const items = response.data.data

    commit("SET_ITEMS", items)
  }
})
